import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../models/user';
import { AuthStateActions } from './auth.actions';

/**
 * Authentication process state constants.
 */
export enum AuthProcessState {
    /** State is undefined yet. */
    UNDEFINED = 'undefined',
    /** Initial state. */
    INITIAL = 'initial',
    /** Authentication is being processed. */
    PROCESSING = 'processing',
    /** Authentication was rejected. */
    REJECTED = 'rejected',
    /** Authentication was completed. */
    AUTHENTICATED = 'authenticated',
    /** Signed out was completed. */
    SIGNED_OUT = 'signed-out',
}

/**
 * Authentication state interface.
 */
export interface AuthState {
    /** User object. */
    user: User;

    /** Authentication process state. */
    state: AuthProcessState;

    /** Authentication process error. */
    error: string;
}

const initialState: AuthState = {
    user: null,
    state: AuthProcessState.UNDEFINED,
    error: null,
};

/**
 * Authentication state reducers definition.
 */
const reducer = createReducer(
    initialState,
    // ============ Authentication state actions.
    on(AuthStateActions.loginCompleted, (state, { user }) => {
        return {
            ...state,
            user,
            state: AuthProcessState.AUTHENTICATED,
        };
    }),
    on(AuthStateActions.loginFailed, (state, { error }) => {
        return {
            ...state,
            user: null,
            state: AuthProcessState.REJECTED,
            error,
        };
    }),
    on(AuthStateActions.logoutCompleted, state => {
        return {
            ...state,
            user: null,
            state: AuthProcessState.SIGNED_OUT,
        };
    }),
);

/**
 * Authentication state reducers.
 */
export const authReducer = (state: AuthState, action: Action) => {
    return reducer(state, action);
};
