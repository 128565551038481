import { AppState } from './index';
import { createSelector } from '@ngrx/store';

/**
 * Select authentication state.
 */
export const selectAuthState = (state: AppState) => state.auth;

/**
 * NgRx Authentication state selectors.
 */
export class AuthSelectors {
    /**
     * Select authentication state.
     */
    static readonly select = selectAuthState;

    /**
     * Is user authenticated.
     */
    static readonly isAuthenticated = createSelector(selectAuthState, state => !!state.user);

    /**
     * Authentication process state.
     */
    static readonly state = createSelector(selectAuthState, auth => auth.state);

    /**
     * User data.
     */
    static readonly user = createSelector(selectAuthState, auth => auth.user);
}
