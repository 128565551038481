import { createAction, props } from '@ngrx/store';
import { User } from '../models/user';

/**
 * Authentication API actions.
 */
export class AuthActions {
    /**
     * Initialize authentication.
     * This should only be called once at the beginning of application.
     */
    static readonly initAuth = createAction('[Auth] Init auth');
}

/**
 * Authentication state changing action.
 *
 * NOTE: Those should only be used by effects and tests.
 */
export class AuthStateActions {
    /**
     * Login completed and user is logged in.
     */
    static readonly loginCompleted = createAction(
        '[Auth] Login completed',
        props<{
            /** User object */
            user: User;
        }>(),
    );

    /**
     * Login failed.
     */
    static readonly loginFailed = createAction(
        '[Auth] Login failed',
        props<{
            /** Error description. */
            error: string;
        }>(),
    );

    /**
     * Logout completed.
     */
    static readonly logoutCompleted = createAction('[Auth] Logout completed');
}
