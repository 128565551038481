import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AppState } from '../state';
import { AuthSelectors } from '../state/auth.selectors';
import { AuthProcessState } from '../state/auth.reducers';

export const authGuard: CanActivateFn = (route, state) => {
    return checkAuthentication(true);
};

export const authGuardChild: CanActivateChildFn = (childRoute, state) => {
    return checkAuthentication(true);
};

export const authLoadGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
    return checkAuthentication(true);
};

export const unauthorizedGuard: CanActivateFn = (route: Route, state) => {
    return checkAuthentication(false);
};

function checkAuthentication(requiresAuth: boolean): Observable<boolean> {
    const router = inject(Router);
    const store$ = inject<Store<AppState>>(Store);
    // TODO: implement checking permission here

    return store$.select(AuthSelectors.state).pipe(
        filter(state => state !== AuthProcessState.UNDEFINED),
        take(1),
        switchMap(() => store$.select(AuthSelectors.isAuthenticated).pipe(take(1))),
        switchMap(authenticated => {
            if (requiresAuth) {
                // route should only be accessed by authenticated users
                if (!authenticated) {
                    // unauthenticated, redirect to login
                    router.navigate(['/signin']);
                    return of(false);
                } else {
                    return of(authenticated);
                }
            } else {
                // route should only be accessed by unauthenticated users
                if (!authenticated) {
                    return of(true);
                } else {
                    // user is authenticated
                    return of(false);
                }
            }
        }),
    );
}
